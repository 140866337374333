import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { ConfigProvider } from 'antd';
import {store} from './store';
import TranslateProvider from './providers/TranslateProvider';
import Routes from './routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <StoreProvider store={store}>
          <ConfigProvider>
              <TranslateProvider>
                  <Routes/>
              </TranslateProvider>
          </ConfigProvider>
      </StoreProvider>
  </React.StrictMode>
);
