import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import type { RootState } from '../store';
import { useAppSelector } from '../store/hooks';
import { LOCALES, messages } from '../translations';

const TranslateProvider: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useAppSelector((state: RootState) => state.app);
  const [newLang, setNewLang] = useState<LOCALES>(LOCALES.ENGLISH);

  useEffect(() => {
    setNewLang(language);
  }, [language]);

  return (
    <IntlProvider locale={newLang} messages={messages[newLang] || messages.en}>
      {children}
    </IntlProvider>
  );
};

export default TranslateProvider;
