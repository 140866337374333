import React, {useState} from 'react';
import {LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import {Flex, Image, MenuProps, Row} from 'antd';
import { Layout, Menu, theme } from 'antd';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import 'normalize.css';
import 'antd/dist/reset.css';
import './styles/globals.scss';
import {FormattedMessage} from 'react-intl';
import {useAppDispatch} from './store/hooks';
import {logOut} from './store/slices/app.slice';

const { Header, Content, Sider } = Layout;

export const MainLayout = ()  => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [activeMenuItem, setActiveMenuItem] = useState([location.pathname ? location.pathname.split('/')[1] : '']);
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logOut())
    }

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const handleNavigate = (key: string[]) => {
        setActiveMenuItem(key)
        navigate( `/${key[0]}`);
    }

    const items: MenuProps['items'] = [
        {
            key: 'devices',
            icon: React.createElement(SettingOutlined),
            label: 'Devices',
            onClick: () => handleNavigate(['devices'])
        },
    ];

  return (
      <Layout hasSider>
          <Sider>
              <Row justify={'center'} style={{background:'#001529', padding: '20px 10px 30px 10px'}}><Image src={'/logo.png'} alt={'logo'} width={70} height={60}/></Row>
              <Menu theme='dark' mode='inline' defaultSelectedKeys={activeMenuItem} items={items} />
          </Sider>
          <Layout style={{ marginLeft: 200 }}>
              <Header style={{padding: '0 20px 0 40px', background: colorBgContainer}}>
                  <Flex justify={'end'}><div className={'logout_button'} onClick={handleLogout}><FormattedMessage id={'common.logout'} defaultMessage={'Sign Out'}/> <LogoutOutlined style={{fontSize: 20, marginLeft:10}}/></div></Flex>
              </Header>
              <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                  <div
                      style={{
                          padding: 24,
                          textAlign: 'center',
                          background: colorBgContainer,
                          borderRadius: borderRadiusLG,
                      }}
                  >
                      <Outlet/>
                  </div>
              </Content>
          </Layout>
      </Layout>
  );
}

export default MainLayout;
