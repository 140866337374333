export const loginPage: { [message: string]: string } = {
  'loginPage.heading': 'Pieslēgties kontam',
  'loginPage.email': 'E-pasts',
  'loginPage.password': 'Parole',
  'loginPage.forgot-password': 'Aizmirsāt paroli?',
  'loginPage.action': 'Pieslēgties',
  'loginPage.not-registered': 'Neesat reģistrējies?',
  'loginPage.register-seller': 'Reģistrēties kā graudu pārdevējs',
  'loginPage.register-buyer': 'Reģistrēties kā graudu pircējs',
  'loginPage.forgot_password': 'Aizmirsi paroli?',
  'loginPage.reset_password': 'Atjaunot Jūsu paroli',
  'loginPage.reset_password_alert': 'Uz Jūsu epastu adresi tika nosūtīts paroles atjaunošanas links',
  'loginPage.send': 'Sūtīt'
};
