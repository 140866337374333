'use client';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import styles from './LoginPage.module.scss';
import {useAppDispatch} from '../../store/hooks'; 
import {ILoginParams} from '../../types/app.types';
import {logIn} from '../../store/slices/app.slice';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogin = async (values: ILoginParams) => {
    const params = {
      username: values.username.toLowerCase(),
      password: values.password
    };

    await dispatch(logIn(params));
    navigate('/');
  };
  return (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          <FormattedMessage id='loginPage.heading' />
        </h2>
        <Form
          name='LoginForm'
          className={styles.loginForm}
          layout='vertical'
          size='large'
          initialValues={{ remember: true }}
          onFinish={onLogin}
          autoComplete='off'
        >
          <Form.Item
            label={<FormattedMessage id='loginPage.email' />}
            name='username'
            rules={[
              {
                type: 'email',
                message: 'Incorrect email'
              },
              {
                required: true,
                message: 'Email is empty'
              }
            ]}
          >
            <Input type='email' />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='loginPage.password' />}
            name='password'
            rules={[
              { required: true, message: 'Passwors is empty' },
              {
                pattern: /^().{12,}$/gm,
                message: 'Password is too short'
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className={styles.buttons_blok}>
            <Button type='primary' htmlType='submit' className={styles.submitBtn}>
              <FormattedMessage id='loginPage.action' />
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default LoginPage;
