import {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
    changeLevelDevice,
    getDevicesList, renameDevice, resetDevice,
    selectDevicesList,
    selectLoadingStatusDevice, startDevice, stopDevice
} from '../../store/slices/devices.slice';
import { FormattedMessage, useIntl } from 'react-intl';
import {EditOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import {Button, Flex, Input, Modal, Row, Select, Table, Tag} from 'antd';
import dayjs from 'dayjs';
import {
    colorDeviceStatusTag, DEVICE_STATUS,
    translateDeviceStatus,
} from '../../types/enum';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en'

import styles from './DevicesPage.module.scss'
import {createDeviceLevelOptions} from './components/SelectOptions';
import {
    colorDeviceOnlineStatusTag,
    DEVICE_ONLINE_STATUS,
    translateDeviceOnlineStatus
} from '../../types/enum/EDeviceOnlineStatus';

let interval: NodeJS.Timer;

const DevicesPage = () => {
    dayjs.extend(relativeTime);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const levelOption = createDeviceLevelOptions();
    const loadingStatus = useAppSelector(selectLoadingStatusDevice);
    const list = useAppSelector(selectDevicesList);
    const [id, setId] = useState<string>('');
    const [name, setName] = useState<string>('');
    const delayOffline = process.env.REACT_APP_DELAY_TO_OFFLINE_MINUTES ?? 5;
    const delayAway = process.env.REACT_APP_DELAY_TO_ONLINE_AWAY_MINUTES ?? 1;


    const startTokenRefreshInterval = () => {
        clearTimeout(interval)

        interval = setTimeout(() => {
            dispatch(getDevicesList({filters: {}, sort: {createdAt: 'DESC'}, offset: 0, limit: 100}));
        }, 5000);
    };

    const refresh = () => {
        setTimeout(() => {
            if (loadingStatus === 'succeeded') {
                dispatch(getDevicesList({filters: {}, sort: {createdAt: 'DESC'}, offset: 0, limit: 100}));
            }
        }, 1000);
    }

    const handleRename = (event: SyntheticEvent) => {
        event.stopPropagation();
        dispatch(renameDevice({id, name})).unwrap();
        setId('');
        setName('');
        refresh();
    }

    const isMoreThanMinutes = (lastActivityTime: Date, time: number) => dayjs().diff(lastActivityTime, 'minute') > time;

    useEffect(() => {
        dispatch(getDevicesList({filters: {}, sort: {createdAt: 'DESC'}, offset: 0, limit: 100}))
    }, [dispatch]);

    startTokenRefreshInterval()


    const columns = [
        {
            title: intl.formatMessage({ id: 'device.uuid', defaultMessage: 'ID' }),
            dataIndex: 'uuid',
            key: 'uuid',
            width: '1%',
        },
        {
            title: intl.formatMessage({ id: 'device.name', defaultMessage: 'Name' }),
            dataIndex: 'name',
            key: 'name',
            width: '10%'
        },
        {
            title: intl.formatMessage({ id: 'common.online_status', defaultMessage: 'Online status' }),
            dataIndex: 'online_status',
            key: 'online_status',
            width: '1%'
        },
        {
            title: intl.formatMessage({ id: 'common.status', defaultMessage: 'Status' }),
            dataIndex: 'status',
            key: 'status',
            width: '1%'
        },
        {
            title: intl.formatMessage({ id: 'device.level', defaultMessage: 'Level' }),
            dataIndex: 'level',
            key: 'level',
            width: '1%'
        },
        {
            title: intl.formatMessage({ id: 'device.start_stop', defaultMessage: 'Start / Stop' }),
            dataIndex: 'start_stop',
            key: 'start_stop',
            width: '5%'
        },
        {
            title: intl.formatMessage({ id: 'device.reset', defaultMessage: 'Reset' }),
            dataIndex: 'reset',
            key: 'reset',
            width: '5%'
        },
        {
            title: intl.formatMessage({ id: 'device.completed', defaultMessage: 'Completed' }),
            dataIndex: 'completed',
            key: 'completed',
            width: '1%'
        },
        {
            title: intl.formatMessage({ id: 'common.created_at', defaultMessage: 'Created At' }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '3%'
        },
        {
            title: intl.formatMessage({ id: 'common.updatedAt', defaultMessage: 'Updated At' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '3%'
        },
        {
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            width: '1%'
        }
    ];

    const getTableData = useMemo(
        () =>
            list.items.map(el => ({
                key: el._id,
                uuid: el._id.split('-')[0].toUpperCase().slice(0, 8) ,
                name: <Flex align='center' justify='space-between' gap={10}>
                    <div className={styles.ip_name_blok}>
                        <div>{el.name }</div>
                        <div className={styles.ip_name_blok_bottom}>{el.ip }</div>
                    </div>

                    <EditOutlined
                        title={'Edit'}
                        className={styles.edit_button}
                        onClick={event => {
                            event.stopPropagation();
                            setId(el._id);
                            setName(el.name);
                        }}
                    />
                </Flex>,
                online_status: <div className={styles.ip_name_blok}>
                        <div>
                            {isMoreThanMinutes(el.lastActivityAt, + delayOffline) ? (
                                <Tag color={colorDeviceOnlineStatusTag(DEVICE_ONLINE_STATUS.OFFLINE)}>
                                    {translateDeviceOnlineStatus(DEVICE_ONLINE_STATUS.OFFLINE)}
                                </Tag>
                            ) : (
                                <Tag
                                    color={colorDeviceOnlineStatusTag(DEVICE_ONLINE_STATUS.ONLINE, isMoreThanMinutes(el.lastActivityAt, +delayAway))}>
                                    {translateDeviceOnlineStatus(DEVICE_ONLINE_STATUS.ONLINE)}
                                </Tag>
                            )
                            }
                        </div>
                        <div>
                            {dayjs(el.lastActivityAt).fromNow()}
                        </div>
                    </div>,
                status: el.status ? (
                    <Tag color={colorDeviceStatusTag(el.status)}>
                        {translateDeviceStatus(el.status)}
                    </Tag>
                ) : (
                    ''
                ),
                level: <Select
                            options={levelOption}
                            className={styles.select_level}
                            value={el.level}
                            onSelect={(value) => {
                                if(value !== el.level) {
                                    dispatch(changeLevelDevice({id: el._id, level: value})).unwrap();
                                    refresh();
                                }
                            }}
                        />,
                start_stop: el.status === DEVICE_STATUS.BLOCKED
                    ?   <Button
                            type={'primary'}
                            size={'small'}
                            className={styles.start_button}
                            onClick={(event) => {
                                event.stopPropagation();
                                dispatch(startDevice({id: el._id})).unwrap()
                                refresh();
                            }}
                        >
                            Start
                        </Button>
                    :   <Button
                            type={'primary'}
                            size={'small'}
                            className={styles.stop_button}
                            onClick={(event) => {
                                event.stopPropagation();
                                dispatch(stopDevice({id: el._id})).unwrap()
                                refresh();
                            }}
                        >
                            Stop
                        </Button>,
                reset: <Button
                            type={'primary'}
                            size={'small'}
                            className={styles.reset_button}
                            onClick={(event) => {
                                event.stopPropagation();
                                dispatch(resetDevice({id: el._id})).unwrap()
                                refresh();
                            }}
                        >
                            Reset
                        </Button>,
                completed: '',
                createdAt: dayjs(el.createdAt).format('DD.MM.YYYY HH:mm:ss'),
                updatedAt: dayjs(el.updatedAt).format('DD.MM.YYYY HH:mm:ss'),
                // edit:
            })),
        [delayAway, delayOffline, dispatch, levelOption, list.items]
    );

    return (
        <div className={styles.wrapper}>
            <Flex align='center' justify='space-between'>
                <h2 className={styles.title}>
                    <FormattedMessage id='device.header' defaultMessage={'Devices'} />
                </h2>
                {/*<Button*/}
                {/*    icon={<PlusOutlined />}*/}
                {/*    className={styles.headerBtn}*/}
                {/*    onClick={() => {*/}
                {/*        navigate('/device/create');*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {' '}<FormattedMessage id='device.button_new' defaultMessage={'New device'} />*/}
                {/*</Button>*/}
            </Flex>
                <Table
                    loading={loadingStatus === 'loading'}
                    size='middle'
                    className={styles.table}
                    dataSource={getTableData}
                    columns={columns}
                    // onRow={row => ({
                    //     onClick: () => navigate(`/providers/${row.key}`)
                    // })}
                />

            {id !== '' &&
                <Modal
                    title={<><ExclamationCircleFilled style={{color: 'orange', marginRight: 5}}/> Do you want to rename these device?</>}
                    open={id !== ''}
                    okText='Rename'
                    okButtonProps={{type: 'primary'}}
                    cancelText='Cancel'
                    onOk={handleRename}
                    onCancel={() => {
                        setId('');
                        setName('');
                    }}
                >
                    <Row style={{margin: '30px 0 0 0'}}>
                        <Input value={name} onChange={e => setName(e.target.value)} placeholder='Name' />
                    </Row>
                </Modal>
            }
        </div>
    );
};

export default DevicesPage;
