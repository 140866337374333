import { ObjectValues } from '../common';

export const DEVICE_LEVEL = {
  LOBBY: 'VR_LobbytMap',
  BASE: 'VR_BaseMap',
  FIRST: 'VR_FirstMap',
  START: 'VR_StartMap',
  SECOND: 'VR_ModernAprtment'
} as const;

export type EDeviceLevel = ObjectValues<typeof DEVICE_LEVEL>;

const devicesLevelTranslations = {
  [DEVICE_LEVEL.LOBBY]: 'Lobby',
  [DEVICE_LEVEL.BASE]: 'Base',
  [DEVICE_LEVEL.FIRST]: 'First',
  [DEVICE_LEVEL.START]: 'Start',
  [DEVICE_LEVEL.SECOND]: 'Second',
} satisfies Record<EDeviceLevel, string>;

export const getEnumDeviceLevel = (value: string) => {
  if (value === 'Lobby') return DEVICE_LEVEL.LOBBY;
  if (value === 'Base') return DEVICE_LEVEL.BASE;
  if (value === 'First') return DEVICE_LEVEL.FIRST;
  return DEVICE_LEVEL.START;
};

export const translateDeviceLevel = (level: EDeviceLevel) => devicesLevelTranslations[level];