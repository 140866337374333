import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import {useAppSelector} from '../store/hooks';
import MainLayout from '../MainLayout';

const ProtectedRoute = () => {

  const { isAuth } = useAppSelector((state: RootState) => state.app);
  if (!isAuth) {
    return <Navigate to='/auth/login' />;
  }

  return <MainLayout />;
};

export default ProtectedRoute;
