export const loginPage: { [message: string]: string } = {
  'loginPage.heading': 'Sign in to your account',
  'loginPage.email': 'E-mail',
  'loginPage.password': 'Password',
  'loginPage.forgot-password': 'Forgot password?',
  'loginPage.action': 'Log in',
  'loginPage.not-registered': 'Not registered?',
  'loginPage.register-seller': 'Register as a grain seller',
  'loginPage.register-buyer': 'Register as a grain buyer',
  'loginPage.forgot_password': 'Forgot password?',
  'loginPage.reset_password': 'Reset your password',
  'loginPage.reset_password_alert': 'A password reset link was sent to your email address',
  'loginPage.send': 'Send'
};
