import { ObjectValues } from '../common';

export const DEVICE_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked'
} as const;

export type EDeviceStatus = ObjectValues<typeof DEVICE_STATUS>;

const devicesStatusTranslations = {
  [DEVICE_STATUS.ACTIVE]: 'Active',
  [DEVICE_STATUS.BLOCKED]: 'Blocked',
} satisfies Record<EDeviceStatus, string>;

export const getEnumDeviceStatus = (value: string) => {
  if (value === 'active') return DEVICE_STATUS.ACTIVE;
  return DEVICE_STATUS.BLOCKED;
};

export const colorDeviceStatusTag = (status: EDeviceStatus) => {
  switch (status) {
    case DEVICE_STATUS.ACTIVE:
      return 'green';
    case DEVICE_STATUS.BLOCKED:
      return 'volcano';
    default:
      return '';
  }
};

export const translateDeviceStatus = (status: EDeviceStatus) => devicesStatusTranslations[status];
