import { configureStore } from '@reduxjs/toolkit';
import app from './slices/app.slice';
import devices from './slices/devices.slice';

export const store = configureStore({
  reducer: {
    app,
    devices
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
