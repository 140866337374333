import { en } from './en';
import { LOCALES } from './locales';
import { lv } from './lv';

export const messages = {
  [LOCALES.ENGLISH]: {
    ...en
  },
  [LOCALES.LATVIAN]: {
    ...lv
  }
};
