import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { memoize } from 'proxy-memoize';
import {api, setParams} from '../../services/api.service';
import { RootState } from '../index';
import {IDataCollection, IParams} from '../../types/common';
import {IDevice, IDevicePost} from '../../types/device.types';
 
const apiSection = '/api/device';

export const getDevicesList = createAsyncThunk(
    'devices/getDevicesList',
    async (params: IParams): Promise<IDataCollection<IDevice>> => {
      const { data } = await api.get<IDataCollection<IDevice>>(apiSection, setParams(params));
      return data;
    }
);

export const getDevices = createAsyncThunk(
    'devices/getDevices',
    async (uuid: string): Promise<IDevice> => {
      const { data } = await api.get<IDevice>(`${apiSection}/${uuid}`);
      return data;
    }
);

export const createDevice = createAsyncThunk(
    'devices/createDevice',
    async (params: { data: IDevicePost }): Promise<IDevice> => {
      const { data } = await api.post<IDevice>(apiSection, params.data);
      return data;
    }
);

export const updateDevice = createAsyncThunk(
    'devices/updateDevice',
    async (params: { data: IDevicePost; id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}`, params.data);
      return data;
    }
);

export const renameDevice = createAsyncThunk(
    'devices/renameDevice',
    async (params: { name: string; id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}/name`, {name: params.name});
      return data;
    }
);

export const changeLevelDevice = createAsyncThunk(
    'devices/changeLevelDevice',
    async (params: { level: string; id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}/level`, {level: params.level});
      return data;
    }
);

export const startDevice = createAsyncThunk(
    'devices/startDevice',
    async (params: { id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}/start`);
      return data;
    }
);

export const stopDevice = createAsyncThunk(
    'devices/stopDevice',
    async (params: { id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}/stop`);
      return data;
    }
);

export const resetDevice = createAsyncThunk(
    'devices/resetDevice',
    async (params: { id: string }): Promise<void> => {
      const { data } = await api.put(`${apiSection}/${params.id}/reset`);
      return data;
    }
);

export const deleteDevice = createAsyncThunk(
    'devices/deleteDevice',
    async (params: { uuid: string }): Promise<void> => {
      const { data } = await api.delete(`${apiSection}/${params.uuid}`);
      return data;
    }
);

interface ProvidersState {
    devicesList: IDataCollection<IDevice>;
    device: IDevice | null;
    loadingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState = {
    devicesList: {items: [], totalCount: 0},
    device: null,
    loadingStatus: 'idle'
} as ProvidersState;

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getDevicesList
    builder
      .addCase(getDevicesList.pending, state => {
      })
      .addCase(getDevicesList.fulfilled, (state, action) => {
        state.devicesList = action.payload
      })
      .addCase(getDevicesList.rejected, state => {
      });

    // getDevices
    builder
      .addCase(getDevices.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.loadingStatus = 'succeeded';
        state.device = action.payload
      })
      .addCase(getDevices.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Device not found')
      });

    // createDevice
    builder
      .addCase(createDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(createDevice.fulfilled, (state, action) => {
        state.loadingStatus = 'succeeded';
        state.device = action.payload
        message.success('Device created successfully')
      })
      .addCase(createDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to creating device')
      });

    // updateDevice
    builder
      .addCase(updateDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(updateDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device edited successfully')
      })
      .addCase(updateDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to editing device')
      });

    // changeLevelDevice
    builder
      .addCase(changeLevelDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(changeLevelDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Level edited successfully')
      })
      .addCase(changeLevelDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to editing level')
      });

    // renameDevice
    builder
      .addCase(renameDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(renameDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device edited successfully')
      })
      .addCase(renameDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to editing device')
      });

    // startDevice
    builder
      .addCase(startDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(startDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device start successfully')
      })
      .addCase(startDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to start device')
      });

    // stopDevice
    builder
      .addCase(stopDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(stopDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device stop successfully')
      })
      .addCase(stopDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to stop device')
      });

    // resetDevice
    builder
      .addCase(resetDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(resetDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device reset successfully')
      })
      .addCase(resetDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to reset device')
      });

    // deleteDevice
    builder
      .addCase(deleteDevice.pending, state => {
        state.loadingStatus = 'loading';
      })
      .addCase(deleteDevice.fulfilled, state => {
        state.loadingStatus = 'succeeded';
        message.success('Device deleted successfully')
      })
      .addCase(deleteDevice.rejected, state => {
        state.loadingStatus = 'failed';
        message.error('Failed to deleting device')
      });
  }
});

export const selectLoadingStatusDevice = memoize((state: RootState) => state.devices.loadingStatus);
export const selectDevicesList = memoize((state: RootState) => state.devices.devicesList);
export const selectProvider = memoize((state: RootState) => state.devices.device);

export default devicesSlice.reducer;
