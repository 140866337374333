import { ObjectValues } from '../common';

export const DEVICE_ONLINE_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline'
} as const;

export type EDeviceOnlineStatus = ObjectValues<typeof DEVICE_ONLINE_STATUS>;

const devicesOnlineStatusTranslations = {
  [DEVICE_ONLINE_STATUS.ONLINE]: 'Online',
  [DEVICE_ONLINE_STATUS.OFFLINE]: 'Offline',
} satisfies Record<EDeviceOnlineStatus, string>;

export const getEnumDeviceStatus = (value: string) => {
  if (value === 'online') return DEVICE_ONLINE_STATUS.ONLINE;
  return DEVICE_ONLINE_STATUS.OFFLINE;
};

export const colorDeviceOnlineStatusTag = (status: EDeviceOnlineStatus, away?: boolean) => {
  switch (status) {
    case DEVICE_ONLINE_STATUS.ONLINE:
      return away ? 'warning' : 'green';
    case DEVICE_ONLINE_STATUS.OFFLINE:
      return 'red';
    default:
      return '';
  }
};

export const translateDeviceOnlineStatus = (status: EDeviceOnlineStatus) => devicesOnlineStatusTranslations[status];
