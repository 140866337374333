'use client';

import axios from 'axios';
import {IToken} from '../types/app.types';
import {refreshToken, selectRefreshTokenRequestInProgress} from '../store/slices/app.slice';
import {store} from '../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
    async config => {
      const session = JSON.parse(localStorage?.getItem('sessionEDU') as string) || '';
      const accessToken = session.accessToken || null;

      if (accessToken) {
        if (config?.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        if (new Date(session.accessTokenExpiresAt) < new Date()) {
          const isRefreshTokenInProgress = selectRefreshTokenRequestInProgress(store.getState());

          if (!isRefreshTokenInProgress) {
            let token;
            await store.dispatch(refreshToken()).then(response => {
              const payload: IToken = response?.payload as IToken;
              token = payload.accessToken;
            });

            // Обновляем заголовок с новым accessToken
            if (config?.headers) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          }
        }
      }
      return config;
    },
    error => Promise.reject(error)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setParams = (rawParams: any) => {
  let params = {};

  Object.keys(rawParams).forEach(key => {
    if (rawParams[key] !== undefined) {
      params = { ...params, [key]: rawParams[key] };
    }
  });

  const config = {
    params: {
      ...params
    }
  };

  return config;
};