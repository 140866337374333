import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { api } from '../../services/api.service';
import { LOCALES } from '../../translations';
import { ILoginParams, IToken } from '../../types/app.types';
import { RootState } from '..';

export const logIn = createAsyncThunk('app/logIn', async (params: ILoginParams) => {
  const response = await api.post<IToken>('/api/auth/login', {
    ...params
  });

  return response.data;
});

export const refreshToken = createAsyncThunk('app/refreshToken', async (_, { rejectWithValue }) => {

  const user = JSON.parse(localStorage?.getItem('sessionEDU') as string) || {};
  if (user.refreshToken !== '') {

    const response = await api.post<IToken>('/api/auth/refresh', {
      token: user.refreshToken
    });
    return response.data as IToken;
  }

  return rejectWithValue({});
});

interface AppState {
  language: LOCALES;
  isAppLoading: boolean;
  isAuth: boolean;
  isShowMobileMenu: boolean;
  isWaitingRefreshToken: boolean;
}

const initialState = {
  language: localStorage?.getItem('EDU') || 'en',
  isAppLoading: false,
  isAuth: localStorage?.getItem('sessionEDU') !== null,
  newRegistration: {},
  isShowMobileMenu: false,
  isWaitingRefreshToken: false
} as AppState;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initUser: state => {
      const user = JSON.parse(localStorage?.getItem('sessionEDU') as string) || {};

      state.isAuth = !isEmpty(user);
      state.isAppLoading = false;
    },
    logOut: state => {
      state.isAuth = !state.isAuth;
      localStorage?.removeItem('sessionEDU');
    },
    setLanguage: (state, action: PayloadAction<LOCALES>) => {
      state.language = action.payload;
    },
    toggleMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileMenu = action.payload;

    }
  },
  extraReducers: builder => {
    // login
    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.isAuth = true;
      const sessionParams = {
        accessToken: payload.accessToken,
        accessTokenExpiresAt: payload.accessTokenExpiresAt,
        refreshToken: payload.refreshToken,
        refreshTokenExpiresAt: payload.refreshTokenExpiresAt,
      };

      localStorage.setItem('sessionEDU', JSON.stringify(sessionParams));
    });
    builder.addCase(logIn.rejected, state => {
      state.isAuth = false;
      message.error('Login error');
    });

    // refreshToken
    builder.addCase(refreshToken.pending, state => {
      state.isWaitingRefreshToken = true;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.isAuth = true;
      const sessionParams = {
        accessToken: payload.accessToken,
        accessTokenExpiresAt:payload.accessTokenExpiresAt,
        refreshToken: payload.refreshToken,
        refreshTokenExpiresAt: payload.refreshTokenExpiresAt
      };

      state.isWaitingRefreshToken = false;
      localStorage.setItem('sessionEDU', JSON.stringify(sessionParams));
    });
    builder.addCase(refreshToken.rejected, state => {
      state.isAuth = false;
      state.isWaitingRefreshToken = false;
      localStorage?.removeItem('sessionEDU');
    });
  }
});

export const { initUser, logOut, setLanguage, toggleMobileMenu } = appSlice.actions;

export const selectRefreshTokenRequestInProgress = (state: RootState) => state.app.isWaitingRefreshToken;

export default appSlice.reducer;
