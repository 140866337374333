import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import DevicesPage from '../pages/DevicesPage/DevicesPage';
import ProtectedRoute from './ProtectedRoute';
import LoginPage from '../pages/LoginPage/LoginPage'; 
const Routes = () => {

  const routesForPublic = [
    {
      path: '/auth/login',
      element: <LoginPage />
    },
  ];

  const routesForAuthenticatedOnly = [
      {
          path: '/',
          element: <ProtectedRoute />,
          children: [
              {
                  path: '/devices',
                  element: <DevicesPage />
              },
              {
                  path: '/',
                  element: <Navigate to='/devices' />
              },
              {
                  path: '*',
                  element: <Navigate to='/devices' />
              },
          ]
      }
  ];


  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForAuthenticatedOnly
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
